.containerx {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.header {
  width:calc(100% - 3px);
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-button {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ccc;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #a09e9e;
    }
  }
  .false-div {
    height: 26px;
    width: 26px;
  }
  
}

.profile-container {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.profile-alert {
  color: red;
  display: none;
  animation: moveText 1s linear 2;
}

@keyframes moveText {
  0% {
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-15px);
  }
  75% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

.images-container {
  height: 400px;
  width: 600px;
  border: 2px solid #ccc;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
 
  .button-add-image {
    position: absolute;
    bottom: 2px;
    right: 2px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.cover-image {
  margin: 5px;
  border: 1px solid black;
  position: relative;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  img {
    height: 180px;
    width: 180px;
    object-fit: cover;
  }
  div {
    cursor: pointer;
  }
}

.delete-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.upload-phtos-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blueviolet;
  padding-inline: 5px;
  border-radius: 5px;
  padding: 5px;
  color: white;
  &:hover {
    cursor: pointer;
  }
}

.more-image-upload {
  position: absolute;
}

button  {
  cursor: pointer;
}

.text-instruction {
  width: 600px;
  text-align: start;
  font-size: 14px;
}




@media (max-width : 600px) {
  .images-container {
    width: 90%;
    height: 400px;
    display: flex;
  }
  .cover-image {
    width: 45%;
    height: 45%;
    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text-instruction {
    width: 90%;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  margin-right: 5px;
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid white;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}