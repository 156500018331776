.containerz {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  height: 100%;
}

.social-container {
  width: 100%;
}

.alert-box1 {
  display: none;
  width: calc(90% - 5px);
  font-size: 14px;
  color: red;
}

@media (max-width: 630px) {

}
