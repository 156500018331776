.containery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
}

@media (max-width : 630px) {
    .button-container {
        width: 90%;
        grid-template-columns: auto auto;
    }
}