.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  margin-top: 10px;
  width: 100%;
  padding: 30px 10px;
  background-color: #f2f2f2 ;
  > h2 {
    font-size: 25px;
    font-family: Roboto, sans-serif;
  }
  > p {
    font-size: 14px;
    word-wrap: break-word;
    font-family: "Open-sans", sans-serif;
    letter-spacing: 0.7px;
    opacity: 0.9;
    text-align: center;
  }
}

.main {
  display: flex;
  justify-content: space-around;
  padding: 7px;
  .arrow {
    
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
.element {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  margin: 10px;
  text-align: center;
  > p {
    font-family: Tinos;
    font-size: 16px;
    text-align: justify;
    
  }
 
}

.element >.abc {
  background-color: #4762c9;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}



@media (max-width: 1000px) {
  .container1 {
    margin-top: 20px;
  }
  .main {
   
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .arrow {
    transform: rotate(90deg);
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .element {
    width: 95%;
    margin: 0px;
   
  }
}
