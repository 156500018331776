.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  row-gap: 36px;
}

#typeOfDataDisplay {
  margin: 15px 80px 0px;
}

#display-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 80px;
  min-height: 82vh;
}

.grid-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.heart {
  position: absolute;
  z-index: 20;
  right: 10px;
  top: 10px;
}

.grid-item:hover {
  cursor: pointer;
}
.nameRegionImage {
  overflow: hidden;
  display: flex;
  position: relative;
  text-shadow: 3px 4px 7px rgba(0, 0, 0, 1);
 
}
.item-image {
  width: 100%;
  height: 320px;
  overflow: hidden;
}
.grid-item img {
   width: 100%; 
   height:100%;
  object-fit: cover;
  border-radius: 5px;
  max-height: 320px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1); 
  }
}

.nameInfu {
  padding: 5px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}
.detialsContainer {
  position: relative;
}
#name-region-container {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  padding: 4px 5px;
  align-items: center;
}
.nameRegion {
  /* position: absolute; */
  /* bottom: 5px; */
  color: white;
}
.pricing {
  /* position: absolute; */
  /* bottom: 5px; */
  /* right: 5px; */
  font-size: 20px;
  color: white;
  font-weight: bold;

  display: flex;
}

.fieldContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.fields {
  font-size: 12px;
  background-color: rgb(207, 207, 207);
  margin: 2px;
  padding: 3px 5px;
  border-radius: 5px;
  font-family: Roboto;
}

@media (max-width : 1100px) and (min-width : 800px) {
  #typeOfDataDisplay {
    margin: 15px 40px 0px;
  }
  #display-main {
    padding: 10px 40px;
  }
}

@media (max-width : 800px) {
  #typeOfDataDisplay {
    margin: 15px 10px 0px;
  }
  #display-main {
    padding: 10px 10px;
  }
} 
