
#searchFilterContainer
{
  display:flex;
  justify-content: center;
  align-items: center;
}
#searchFilter
{
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr)) auto;
    grid-column-gap:5px;
    width:1000px;
}

.dropbtn
{
    border:1px solid rgb(124, 124, 124);
    border-radius: 10px;
    font-size: 20px;
    padding:5px 0px 5px 5px;
    cursor:pointer;
    display:flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    /* white-space: nowrap;  */
    overflow: hidden; 
    /* text-overflow: ellipsis; */
}

.dropbtn::after {
  content: attr(title); 
  position: absolute;
  top: -30px; 
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 50;
}

.dropbtn:hover::after {
  opacity: 1;
  visibility: visible;
}
#overflows{
 
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.dropdown{
  position: relative;
}
.dropdown-content{
    border:1px solid rgb(0, 0, 0);
    margin-top:5px;
    border-radius: 10px;
    background-color: white;
    /* display: flex; */
    position: absolute;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;

}
.dropPlatfromclass
{
    padding:2px;
    padding-left: 5px;
    display:flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border-bottom: 0.5px solid grey;



}
.dropPlatfromclass:hover{
    background-color: rgb(204, 204, 204);
}
.dropPlatfromclass:last-child
{
    border-bottom: 0px;
}
.fieldClass
{
    font-size: 13.5px;
    display:flex;
    justify-content: center;
    width:max-content;
    background-color: rgb(222, 219, 219);
    padding:5px;
    border-radius: 5px;
    cursor:pointer;
    margin-bottom: 2px;
    /* letter-spacing: 0.8px; */
    font-family: Roboto;
}
.fieldClass:hover{
    background-color: rgb(173, 172, 172);
}

#dropDownField
{
    border:1px solid black;
    display: grid;
    /* grid-template-columns: repeat(3,1fr); */
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Updated */

    border-radius: 10px;
    margin-top: 5px;
    padding: 3px;
    padding-left:5px;
    justify-items: center;
    row-gap: 5px;
}
#countryContainer
{
    border:1px solid black;
    margin-top:5px;
    border-radius: 10px;
    font-size: 12px;
    overflow-y: hidden;
}
#outer
{
    height:70.4px;
    overflow: auto;
}
.countryClass
{
    padding:2px;
    padding-left: 5px;
    font-size:16px;
    display:flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border-bottom: 0.5px solid grey;
    
}
.countryClass:hover{
    background-color: rgb(204, 204, 204);
}
 /* ::-webkit-scrollbar {
    width: 2px;
  }
  
 ::-webkit-scrollbar-track {
    background: #bab9b9;
  }
  
 ::-webkit-scrollbar-thumb {
    background: #000000;
  } */
  
 
 /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }  */
  #range{
    border:1px solid black;
    padding:5px;
    overflow:hidden;
    border-radius: 10px;
    margin-top: 5px;

  }
  #maxandmin
  {
    display:flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 'bold';
  }
  #getData
  {
    /* width:18px;
    height: 18px; */
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    /* border:1px solid red;
     */
    /* border:1px solid red; */
  }
  #getData:hover{
    background-color: #cbc7c7;
    border-radius: 2px;
    
  }
 
  .cross{
    position: absolute;
    top:50%;
    right: 5px;
    /* border:1px solid red; */
    transform: translateY(-50%);
    display:flex;
    justify-content: center;
    align-items: center;
    /* transition: none; */
  }
  @media screen and (max-width:700px)
  {
    #searchFilter
    {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2px;
        
        
    }
    .dropdown,#getData
    {
        width:60%;
        justify-self: center; 
        align-self: center; 
    justify-content: center;
    margin-bottom: 5px;
    
    }

  .dropbtn
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    
    font-size:16px;
   
  }
  
  #getData div
  {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: black;
    border-radius: 15px;
    color:white;
    padding:3px
  }
  #getData:hover{
    text-decoration: underline;
    border-radius: 15px;
    background-color: black;
  }
  }