#featured-container {
  margin: 30px 60px;
  overflow: hidden;
  /* background-color: rebeccapurple; */
}

.featured-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  /* background-color: red; */
}

#display-data-container {
  gap: 10px;
  display: flex;
  max-height: 360px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  /* background-color: aqua; */
}
#display-data-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.f-grid-item {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 270px;
  min-height: 300px;
  border-radius: 5px;
  overflow: hidden;
  
  &:hover {
    cursor: pointer;
  }
}

.f-nameRegionImage {
  display: flex;
  position: relative;
  text-shadow: 3px 4px 7px rgba(0, 0, 0, 1);
}
.f-item-image {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 5px;
  max-height: 320px;
  transition: transform 0.3s ease;
  > img {
    border-radius: 5px;
  }
  &:hover {
    transform: scale(1.1);
  }
}


@media (max-width: 1100px) and (min-width: 800px) {
  #featured-container {
    margin: 15px 40px 0px;
    overflow: hidden;
  }
 
  #display-data-container {
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;  
    /* overflow: hidden; */
  }
  #display-data-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}
@media (max-width: 800px) {
  #featured-container {
    margin: 15px 10px 0px;
  }
  #display-data-container {
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
  #display-data-container::-webkit-scrollbar {
    display: none; 
  }
}
 @media (max-width:500px)
 {
  .f-item-image{
    height: 250px;
    min-height: 250px;
  }
  .f-grid-item{
    min-height: initial;
    min-width: 250px;
  }
 }
