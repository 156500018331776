/* custom form */
.container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.custom-main1 {
  margin: 0px 10px;
  padding-top: 10px;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px dashed rgb(236, 233, 233);
  border-right: 1px dashed rgb(236, 233, 233);
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.checkout-form {
  width: 500px;
  margin-top: 10px;
  > select,
  > input,
  > textarea {
    width: 97%;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 0.8px;
    border-radius: 7px;
  }
  button {
    font-size: 16px;
    font-family: Roboto;
    width: 100px;
    padding: 7px;
    letter-spacing: 0.9;
  }
  > textarea {
    height: 100px;
  }
}

@media (max-width: 600px) {
  .container2 {
    > h2 {
      font-size: 4.5vw;
    }
  }
  .custom-main {
    width: 100%;
  }
  .checkout-form {
    width: 100%;
    > textarea {
      height: 105px;
    }
  }
}

/* predefined order */

.checkout-container-predefined {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 82vh;

  .influencer-profile {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.sub-checkout {
  width: min(100%, 1080px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 5px;
  margin-bottom: 40px;
  > div {
    width: 100%;
    padding: 10px 40px;
  }
  > div > h4 {
    font-size: 30px;
    margin-bottom: 3px;
  }
}

.checkout-body {
  display: flex;
  width: 100%;
  padding: 0px 40px;
  gap: 40px;
  color: #1a1a1ab2;
}

#checkout-details {
  flex: 4;
}

#checkout-email {
  background-color: #f7f7f7;
  padding: 12px 40px 12px 12px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
  letter-spacing: 1.2px;
}

.checkout-profile {
  display: flex;
  margin: 10px 0px;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  > img {
    height: 60px;
    width: 60px;
    object-fit: contain;
    border-radius: 10px;
  }
}

.checkout-summary {
  flex: 3;
  margin-top: 10px;
  padding: 15px 40px;
  box-sizing: border-box;
  box-shadow: -3px rgb(153, 150, 150);

  > h2 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  #line {
    border-top: 1px solid rgb(153, 150, 150);
  }

  .checkout-price-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    > div {
      display: flex;
      margin-bottom: 4px;
      justify-content: space-between;
    }
  }
  .checkout-button {
    width: 100%;
    margin-top: 10px;
  }
}

#profile-container-checkout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 800px) {
  /* .checkout-container-predefined {
        display: block;
        box-sizing: border-box;
        .sub-checkout {
            width: 100%;
        }
    } */
  .checkout-body {
    flex-direction: column;
    padding: 0px 20px;
    gap: 10px;
  }
  .checkout-summary {
    padding: 0px;
  }
  #profile-container-checkout {
    padding-right: 20px;
  }
  .sub-checkout {
    margin-bottom: 10px;
    > div {
      width: 100%;
      padding: 10px 20px;
    }
  }
}
