.navigation-buttons {
    display: flex;
    gap: 10px;
  }
  
  .navigation-buttons button {
    padding: 10px;
    
    color: #ffffff;
    background-color: #74adea;
    border: none;
    border-radius:1px solid blue;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .navigation-buttons button.active {
    background-color: #49c34d;
    transform: scale(1.05);
  }
  
  .navigation-buttons button:hover {
    background-color: #0056b3;
  }
  
  .navigation-buttons button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }