

.main-container {
  width: 100%;
  min-height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
}

.progress-bar-container {
  width: 585px;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-bottom: 10px;
}
.buttonprevnext{
  padding:5px;
  background-color: #4285F4;
  color:white;
  border-radius: 5px;
  border: none;
  margin:0px 5px 0px 0px;
}
.progress-bar {
  height: 10px;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
  background-color: #4285F4;
}

.content-container {
  width: 660px;
  border: 1px solid rgb(235, 228, 228);
  padding: 20px 40px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 7px;
}

.button-submit {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  background-color: #4285F4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  margin-top: 7px;
  text-transform: uppercase;
  letter-spacing: normal;
}

@media (max-width : 630px) {
  .content-container {
    width: 98%;
    padding: 10px;
    margin: 0px 20px;
  }
  .progress-bar-container {
    width: 90%;
    margin-bottom: 5px;
  }
}





