.who-container-body {
  display: flex;
  justify-content: center;
  min-height: 82vh;
}

.whoareyou {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}


/* Button styling */
.button-submit {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Hover effect for button */
.button-submit:hover {
  background-color: #0056b3;
}

/* Focus effect for button */
.button-submit:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.ask-type-container {
  border: 1px solid rgb(204, 201, 201);
  box-shadow: 24;
  border-radius: 10px;
  width: 660px;
  margin-top: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
