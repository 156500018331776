#homefaq-container {
    margin: 30px 80px;
}

@media (max-width : 1100px) and (min-width : 800px) { 
    #homefaq-container {
        margin: 15px 40px;
    }
}

@media (max-width : 800px) { 
    #homefaq-container {
        margin: 10px ;
    }
}



