.footer-container {
  padding: 10px 80px;
  color: #424242;
  background-color: #f2f2f2;
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(213, 211, 211);
    padding-bottom: 5px;
  }
  #footer-logo {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
  }
  #footer-social {
    display: flex;
    gap: 20px;
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    #footer-link-c {
        display: grid;
        width: min(100%,600px);
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); 
        row-gap: 10px;
        font-size: 14px;
    }
  }
}
.footer-link {
    text-decoration: none;
    color: #424242;
    &:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width : 800px) {
    .footer-container {
        padding: 10px 10px;
    }
    .footer-top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #footer-social {
        gap: 5px;
       >p{
        display: none;
       }
    }
 
    .footer-bottom {
        
        gap: 10px;
        align-items: center;
    }
}

@media screen and (max-width : 1100px) and (min-width : 800px) {
    .footer-container {
        padding: 10px 40px;
    }
}