.navbar-main-container {
  border-bottom: 0.5px solid rgb(177, 177, 177);
}
#navbarcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px 40px;
  padding: 3px;
  font-size: 20px;
}
#nameandlogo {
  display: flex;
  align-items: center;
  font-size: 24px;
}
#menu {
  display: none;
}

#accountDetails img {
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 2px solid rgb(172, 169, 169);
  cursor: pointer;
}

#navbardetails {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 5px;
}

.navDetailsClass {
  display: flex;
  justify-content:flex-end;
  align-items: center;
}
.unauth
{
  display: flex;
  justify-content:center;
  align-items: center;
}
.user-input-status {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
#login {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: black;
  }
}
#signup {
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
}
#join-button {
  text-transform: capitalize;
  padding: 0px;
  font-size: 16px;
}
.navDetailsClass > span {
  cursor: pointer;
}
.navDetailsClass > span:hover {
  text-decoration: underline;
}
.unauth > span {
  cursor: pointer;
}
.unauth > span:hover {
  text-decoration: underline;
}
#account:hover {
  text-decoration: none;
}
/* sign-up and sign-in modal style */

.modal-container-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid rgb(204, 201, 201);
  box-shadow: 24;

  border-radius: 10px;
}
.modal-container-login {
  width: min(80%,800px);
  display: grid;
  padding: 20px;
  height: 450px;
  grid-template-columns: 1fr 1fr;
}

.left-login-modal {
  background-image: url("./assets/client.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Tinos;
  > h3 {
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    margin-top: 3px;
    color: rgb(32, 31, 31);
    text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
  }
}
.modal-container-div:focus {
  outline: none;
}
.right-login-modal {
  padding: 10px 20px;
  row-gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Tinos;
  #modal-header {
    > h4 {
      font-size: 24px;
      letter-spacing: 1px;
      font-weight: 700;
    }
    #sign-in-button {
      border: none;
      background-color: white;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
#google-button {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(204, 201, 201);
  padding: 10px;
  align-items: center;
  font-weight: 700;
  border-radius: 5px;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: rgba(149, 157, 165, 0.2) 4px 4px 10px;
  &:hover {
    background-color: #f5f5f5;
  }
  > p {
    letter-spacing: 0.8px;
  }
}
.modal-container-ask {
  width: 800px;
  height: 450px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  > h3 {
    font-size: 24px;
  }
  > h4 {
    font-size: 30px;
    margin: 15px;
  }
  .who-container {
    display: flex;
    gap: 30px;
    margin: 20px 0px;
    > div {
      width: 200px;
      height: 200px;
      border: 1px solid rgb(204, 201, 201);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
      > h5 {
        font-size: 20px;
      }
      > img {
        height: 80%;
        width: 80%;
        object-fit: cover;
      }
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
          rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #navbarcontainer {
    margin: 0px 10px;
  }
  #navbardetails {
    display: none;
  }
  #menu {
    display: flex;
    justify-content: right;
    align-items: center;
    
  }
  #nameandlogo {
    font-size: 20px;
    cursor: pointer;
  }
  .modal-container-login {
    width: 90%;
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
 

  .left-login-modal, .right-login-modal {
    height: auto;
    padding: 10px;
   
  }

  .left-login-modal {
    background-size: cover;
    background-position: center;
    text-align: center;
  }
  .left-login-modal{
    display: none;
  }

  .right-login-modal {
    padding: 10px 15px;
    row-gap: 30px;
  }

  .modal-container-ask {
    width: 90%;
    height: auto;
    padding: 15px;
  }

  .who-container {
    flex-direction: column;
    align-items: center;
  }

  .who-container > div {
    width: 100%;
    max-width: 300px;
    height: auto;
    padding: 20px;
  }

  #google-button {
    padding: 8px;
  }

  #google-button > p {
    font-size: 14px;
  }
}
