
#horizontalcontainer
{
  position: absolute;
  z-index: 1;
  right:0px;
  width:150px;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: transform 0.5s ease-out;
  box-shadow: -1px 2px 5px 2px rgb(199, 199, 199);

}
.horizontaldetails
{
    color: white;
    text-decoration: none;
    display: flex;
    background-color: black;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey;
    padding:8px;
    font-size: 15px;
    cursor:pointer;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}
.horizontaldetails:hover{
    background-color: rgb(147, 147, 147);
}
.horizontaldetails:first-child{
    border-top-left-radius: 5px;
}
.horizontaldetails:last-child
{
    border: none;
    border-bottom-left-radius: 5px;
}

@media screen and (min-width:500px) {
    /* #horizontalcontainer
    {
        display:none;
    }   */
}


