.faq-container {
    width: 1080px;
    padding: 10px;

}

@media (max-width : 600px) {
    .faq-container {
        width: 100%;
    }
}