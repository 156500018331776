.profile-main {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #f2f3f5;
  min-height: 82vh;
}

.container {
  width: 1080px;
  padding: 0px 10px;
  border-left: 1px dashed rgb(236, 233, 233);
  border-right: 1px dashed rgb(236, 233, 233);
  box-sizing: border-box;
}

.cover-container {
  width: 100%;
  height: 360px;
  display: flex;
  box-sizing: border-box;
  gap: 4px;
  > div {
    flex: 1;
  }

  .third {
    display: flex;
    grid-template-columns: 2fr 1fr;
    gap: 4px;
    div {
      height: 180px;
      width: 100%;
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 30% 30%;
  }
  /* .three-first {
    background-color: rebeccapurple;
    height: 220px;
  } */
  .image-three {
    display: grid;
    grid-template-rows: 1fr 1fr;
    > div {
      height: 180px;
      width: 100%;
    }
  }
}

.profile-div {
  display: flex;
  width: 100%;

  margin-top: 10px;
  margin-bottom: 5px;
  .name {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 0px;
    text-transform: capitalize;
  }
}

.image-div {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.image-div > img {
  height: 100px;
  width: 100px;
}
.category-container {
  display: flex;
  gap: 5px;
  div {
    background-color: #ccc;
    padding: 2px 5px;
    font-weight: 500;
    border-radius: 5px;
  }
}

.field-container {
  display: flex;
  gap: 15px;
}

.field-element {
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(204, 201, 201);
  border-radius: 3px;
  margin-right: 2px;
  padding: 2px;
  color: black;
  gap: 2px;
  text-decoration: none;
}

.profile-bio {
  margin: 10px 0px;
  text-align: justify;
  overflow-wrap: break-word;
}

.profile-buttons-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profile-buttons {
  border: 1px solid rgba(149, 157, 165, 0.2);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 7px;
  text-decoration: none;
  font-size: 13px;
  font-family: Roboto;
  letter-spacing: 0.8px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #e4e6eb;
  color: black;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #ccced4;
  }
}

.price-box {
  width: 100%;
}

.profile-packages {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  > p {
    font-size: 20px;
    font-weight: 300;
  }
}

.price-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  box-sizing: border-box;
  
}

.price-item-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid rgb(204, 201, 201);
  padding: 14px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  > div {
    margin-bottom: 8px;
  }
  .item-quantity {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    gap: 5px;
    > div {
      border: 1px solid rgb(204, 201, 201);
      padding: 3px 8px;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;
    }
    > div:hover {
      background-color: #1976d2;
      color: white;
      cursor: pointer;
      font-weight: bold;
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
  }
}

.price-items {
  display: flex;
  gap: 10px;
  border: 1px solid black;
  padding: 5px;
}

.custom-offer {
  display: flex;
  width: 50%;
  border: 1px solid rgb(204, 201, 201);
  justify-content: space-between;
  align-items: center;
  gap:20px;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}

.cover-container-mobile {
  display: none;
}

.modal-close-button {
  background-color: #e4e6eb;
  border-radius: 50%;
  padding: 1px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #ccced4;
    cursor: pointer;
  }
}

.profile-modal-textarea {
  width: 100%;
  padding: 5px;
}

.multiple-inputs {
  display: flex;
  width: 250px;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  > input {
    width: 60px;
    height: 20px;
    text-align: center;
  }
  > div {
    width: 60px;
    height: 20px;
    text-align: center;
  }
}
.profilenames {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100px;
}

.item-description {
  opacity: 0.9;
  text-align: justify;
  :first-letter {
    text-transform: capitalize;
  }
}

@media (min-width: 600px) and (max-width: 1070px) {
  .container {
    width: 100%;
    padding: 5px;
  }
  .price-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .price-item-card {
    width: calc(50% - 17px);
  }
  .custom-offer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    padding: 5px 10px;
  }
  .cover-container {
    display: none;
  }

  .cover-container-mobile {
    display: flex;
    width: 100%;
    height: 300px;
    margin: 2px;
    transition: all 1s ease-in-out;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .cover-indicator {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-weight: 800;
    }
  }
  .profile-div {
    flex-wrap: wrap;
  }
  .price-items-container {
    display: block;
  }

  .price-item-card {
    width: 100%;
    margin-bottom: 10px;
  }

  .profile-buttons-container {
    gap: 20px;
    width: 100%;
    flex-direction: row;
    margin: 5px 0px;
  }

  .custom-offer {
    width: 100%;
  }
  .profile-edit-button {
    flex: 1;
  }
}
