.form-container {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    >h2 {
        margin-bottom: 10px;
    }
  }
  
  .form-group {
    margin-bottom: 15px;
    >select {
        width: 100%;
        padding: 8px 8px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    input[type='text'],
    input[type='number'],
    .bio {
      width: 100%;
      padding: 8px 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  

  .bio {
    height: 100px;
  }

  .button-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  