.containercampaign {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 1rem;
}

@media (min-width: 768px) {
  .containercampaign {
    padding: 5rem 1.5rem;
  }
}

@media (min-width: 1024px) {
  .containercampaign {
    padding: 5rem 2rem;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  border: 1px solid red;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
}

@media (min-width: 1024px) {
  .grid {
    gap: 4rem;
  }
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

h1 {
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

@media (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}

.p,
.campaigndescription {
  color: #6b7280;
  font-size: 1.125rem;
  line-height: 1.75;
}

@media (min-width: 768px) {
  .p,
  .campaigndescription {
    font-size: 1.25rem;
  }
}

.criteriagrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.bg-muted {
  background-color: #f3f4f6;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

h3 {
  font-size: 1.125rem;
  font-weight: 600;
}

.text-muted-foreground {
  color: #6b7280;
}

.overflow-hidden {
  overflow: hidden;
}

.mt-12 {
  margin-top: 3rem;
}

@media (min-width: 768px) {
  .mt-12 {
    margin-top: 4rem;
  }
}

@media (min-width: 1024px) {
  .mt-12 {
    margin-top: 5rem;
  }
}

.space-y-8 > * + * {
  margin-top: 2rem;
}

h2 {
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2.25rem;
  }
}

.prose {
  max-width: 65ch;
}

.prose p {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
